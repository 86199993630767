.link {
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 12px;
  padding-block: 4px;
  margin-block: 2px;
  color: #000;
  width: 100%;
  height: 2rem;
  width: 13rem;
  font-size: 18px;
  font-weight: 400;
}
.link:hover {
  /* color: #ffa500; */
  color: #adadad;
}
.activelink {
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 12px;
  padding-block: 4px;
  margin-block: 2px;
  color: #000;
  background-color: #adadad;
  width: 100%;
  color: #fff;
  height: 2rem;
  width: 13rem;
  font-size: 18px;
  font-weight: 400;
}
.select {
  flex: 1;
  display: flex;
  background-color: #000;
  width: 100%;
}


/* video */
.video-main{
  position: relative;
  width: 100%;
  height: 100%;
}
.play-icon{
  height: 50px;
  width: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(0, 0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* Rating */


.Feed-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0rem;
}

.feed-icons {
  display: flex;
  margin: 0.5rem 0;
}

.feed-result {
  font-size: 20px;
  margin: 0.5rem 0;
}

.feed-icon {
  margin: 0.25rem;
  font-size: 30px;
  color: gold;
  cursor: pointer;
}